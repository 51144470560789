<script setup lang="ts">
useHead(() => ({
  meta: [
    {
      name: 'robots',
      content: 'noindex,nofollow',
    },
  ],
}));
</script>

<template>
  <div>
    <slot />
  </div>
</template>

<style>
html {
  font-family: 'Noto Sans JP', sans-serif;
}
a,
button {
  -webkit-tap-highlight-color: transparent;
}
</style>
